import type { Page } from '~~/types/api-common'

export const useMaturationTypeService = () => {
    const listMaturationTypes = async (options: ListMaturationTypeParams = {}): Promise<MaturationType[] | null> => {
        const params: Record<string, any> = {
            page: options.page || 1,
            size: options.size || 20,
        }

        const data = await api<Page<MaturationType>>(
            '/maturation_type/', { params },
        )
        return data.items || null
    }

    return { listMaturationTypes }
}

interface ListMaturationTypeParams {
    size?: number
    page?: number
}

export interface MaturationType {
    id_maturation_type: string
    name: string
}
