<script lang="ts" setup>
import { storeToRefs } from 'pinia'

defineProps<{ isImpersonating?: boolean }>()
const { referenceDate } = storeToRefs(useProcurementStore())
const { impersonatingShopperName } = storeToRefs(useShopperStore())
const { openSidebar } = useSidebar()
const { hideSplashScreen } = useSplashScreen()
const { clearImpersonation } = useShopperStore()

const user = useSupabaseUser()

const isLogged = computed(() => Boolean(user.value))

const onStopImpersonating = async () => {
    clearImpersonation()
    window.location.reload()
}

onMounted(() => {
    hideSplashScreen()
})
</script>

<template>
    <div
        px-6 pt-5 pb-4
        :class="[
            isImpersonating ? 'bg-gray-300 text-gray-700' : 'bg-secondary-700 text-white',
        ]"
    >
        <div
            flex items-center font-600
            h-6
            :class="[
                !isLogged ? '!justify-center' : 'justify-between',
            ]"
        >
            <div v-if="isLogged" data-test-id="navbar-menu" cursor-pointer @click="openSidebar">
                <div i-material-symbols-menu />
            </div>
            <div flex items-center>
                <div i-material-symbols-calendar-today-outline mr-1 relative top--1px />
                <input
                    v-model="referenceDate" type="date" text-13px text-center bg-transparent data-test-id="date-select-input"
                    :class="[
                        isImpersonating ? 'text-gray-700' : 'text-white',
                    ]"
                >
            </div>

            <div v-if="isLogged">
                <app-search-bar />
            </div>
        </div>
        <div v-if="isImpersonating" class="bg-gray-400/30" px-3 py-2 rounded text-dark-100 mt-2 flex items-center>
            <div text-12px text-left>
                <div>
                    Personificando shopper
                </div>
                <div mt-1 data-test-id="impersonate-user-name">
                    <b>{{ impersonatingShopperName }}</b>
                </div>
            </div>
            <cc-button small ml-auto @click="onStopImpersonating">
                Parar
            </cc-button>
        </div>
    </div>
</template>

<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
</style>
