export const useSidebar = () => {
    const isSidebarOpen = useState('is-sidebar-open', () => false)
    const closeSidebar = () => isSidebarOpen.value = false
    const openSidebar = () => {
        isSidebarOpen.value = true
    }

    return { isSidebarOpen, closeSidebar, openSidebar }
}

