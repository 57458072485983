<script lang="ts" setup>
defineProps<{ icon?: string }>()
const isModalOpen = ref(false)

const onOpenModal = () => {
    isModalOpen.value = true
}
</script>

<template>
    <div
        z-99 fixed
        m-2 w-12
        flex items-center justify-center
        class="fab-container"
        bottom-16
    >
        <div
            rounded-36px
            b-2 box-border
            b-primary-300 bg-primary
            text-white p-2 flex
            text-5
            items-center
            class="fab"
            data-test-id="fab"
            @click="onOpenModal"
        >
            <div
                :class="[
                    icon ?? 'i-material-symbols-add',
                ]"
            />
        </div>
        <modal v-model="isModalOpen">
            <slot />
        </modal>
    </div>
</template>

<style scoped>
.fab{
    box-shadow: 0px 4px 12px 0px rgba(24, 154, 102, 0.32);
}

.fab-container {
    right: max(.5rem, calc(((100vw - 420px) / 2) + .5rem));
}
</style>
