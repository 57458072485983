<script lang="ts" setup>
import { useMotions } from '@vueuse/motion'
import { storeToRefs } from 'pinia'
const { isImpersonatingShopper } = storeToRefs(useShopperStore())

const { searchQuery } = storeToRefs(useProcurementStore())
const motions = useMotions()

const isFocused = ref(false)
const searchInput = ref<HTMLElement | null>(null)

const onClick = () => {
    isFocused.value = true
    setTimeout(() => {
        searchInput.value?.focus()
    }, 100)
}
const onBlur = () => isFocused.value = false
const onFocus = () => isFocused.value = true
</script>

<template>
    <div
        flex items-center b-1 b-transparent transition rounded box-border
        :class="[
            isFocused || searchQuery ? (isImpersonatingShopper ? '!b-gray-700' : '!b-white') : '',
        ]"
    >
        <transition
            :css="false"
            @leave="(_, done) => motions.searchBar.leave(done)"
        >
            <div
                v-if="isFocused || searchQuery"
                v-motion="'searchBar'"
                :initial="{ opacity: 0, maxWidth: 0 }"
                :enter="{ opacity: 1, maxWidth: 156, transitionDuration: '100ms' }"
                :leave="{ opacity: 0, maxWidth: 0 }"
            >
                <input
                    ref="searchInput"
                    v-model="searchQuery" type="text"
                    w-full h-full box-border text-14px p-1
                    b-none bg-transparent outline-none
                    placeholder="Buscar..."
                    class=""
                    data-test-id="navbar-search-input"
                    :class="[
                        isImpersonatingShopper ? 'placeholder:text-gray-700/50' : 'placeholder:text-white/50',
                    ]"
                    @blur="onBlur"
                    @focus="onFocus"
                >
            </div>
        </transition>
        <div mx-1 cursor-pointer i-material-symbols-search data-test-id="navbar-search" @click="onClick" />
    </div>
</template>
