<script lang="ts" setup>
import { datadogRum } from '@datadog/browser-rum'
import { storeToRefs } from 'pinia'

const { isLocked } = useScroll()
const user = useSupabaseUser()
const page = ref<HTMLElement | null>(null)
const isPageScrollLocked = useScrollLock(page)
const { shopper, isImpersonatingShopper, userIsAShopper } = storeToRefs(useShopperStore())
const { getUserShopper, refreshImpersonation } = useShopperStore()
const { fetchProviders, fetchProvidersAbleToShop } = useProviderStore()
const { isSplashScreenVisible } = useSplashScreen()
useRole()

const impersonatingShopperIdStorage = useLocalStorage<string | null>('impersonatingShopperId', null)

watchEffect(async () => {
    if (!shopper.value && user.value && !impersonatingShopperIdStorage.value) {
        datadogRum.setUser({
            id: user.value.id,
            email: user.value.email,
        })
        await getUserShopper()
        if (shopper.value) {
            await fetchProviders()
            await fetchProvidersAbleToShop()
        }
    }

    if (impersonatingShopperIdStorage.value && !isImpersonatingShopper.value) {
        if (process.client) {
            await refreshImpersonation(impersonatingShopperIdStorage.value)
        }
        await fetchProviders()
        await fetchProvidersAbleToShop()
    }
})

watchEffect(() => {
    isPageScrollLocked.value = isLocked.value
})
</script>

<template>
    <main>
        <app-splash-screen
            v-if="isSplashScreenVisible"
            class="text-center container-width"
            overflow-scroll
            overflow-x-hidden
            h-screen
            m-auto
            font-sans
        />
        <div
            v-show="!isSplashScreenVisible"
            ref="page"
            class="text-center container-width"
            m-auto
            font-sans
            bg-backgroundlight relative
            overflow-scroll
            overflow-x-hidden
            h-screen flex flex-col text-left
        >
            <lazy-client-only>
                <app-navbar :is-impersonating="isImpersonatingShopper" sticky top-0 z-1 />
                <template #placeholder>
                    <app-navbar sticky top-0 z-1 />
                </template>
            </lazy-client-only>

            <app-sidebar />
            <div grow>
                <slot />
            </div>
            <app-footer />

            <floating-action-button
                v-if="userIsAShopper"
                icon="i-material-symbols-add"
                data-test-id="add-new-purchase"
            >
                <add-new-item-form />
            </floating-action-button>

            <floating-action-button
                v-if="userIsAShopper"
                bottom-30
                icon="i-material-symbols-attach-money"
            >
                <add-new-quotation-form />
            </floating-action-button>

            <app-modal-container />
        </div>
    </main>
</template>

<style scoped>
.container-width {
    max-width: var(--app-max-width);
}
</style>
