<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { ProcurementQuotation } from '~~/composables/services/procurement-quotation-service'
import type { RawProduct } from '~~/composables/services/raw-product-service'
import { MeasureType, PriceType } from '~~/types/buy-event'
import type { LabeledRadioOption } from '~~/types/labeled-radio'

const { closeModal } = useModal()
const { createValidation, field, z } = useValidation()
const { createProcurementQuotation } = useProcurementQuotationStore()
const { shopper } = storeToRefs(useShopperStore())
const user = useSupabaseUser()
const { success } = useNotification()

const selectedRawProduct = ref<RawProduct | null>(null)
const isSavingQuotation = ref<boolean>(false)

const itemMeasureOption = computed(() => {
    const measureOption: LabeledRadioOption = {
        label: '',
        value: '',
    }
    if (selectedRawProduct.value?.quantity_wholesale_unit === undefined) {
        return measureOption
    }

    if (selectedRawProduct.value.quantity_wholesale_unit === 'KG') {
        measureOption.label = 'Granel'
        measureOption.value = MeasureType.Weight
    }
    else {
        measureOption.label = 'Unidade'
        measureOption.value = MeasureType.Unit
    }

    return measureOption
})

const measureOptions = computed<LabeledRadioOption[]>(() => [
    { label: 'Atacado', value: MeasureType.Box },
    itemMeasureOption.value,
])

const priceOptions = computed<LabeledRadioOption[]>(() => [
    { label: 'Preço por Embalagem', value: PriceType.UnitPrice },
    { label: 'Preço Total', value: PriceType.TotalPrice  },
])

const { validate, values: quotation, errors, isValid } = createValidation<ProcurementQuotation>({
    createdBy: field(user.value.email),
    providerId: field('', z.string().min(1))
        .errorMessage('Informe um fornecedor'),
    qualityTypeId: field('', z.string().nullable().refine((): Boolean => {
        const quality = selectedRawProduct.value?.product_policy?.quality_source
        return quality !== 'SOURCING' || (quality === 'SOURCING' && !!quotation.qualityTypeId)
    },
    )).errorMessage('Informe a qualidade do produto'),
    maturationTypeId: field('', z.string().nullable()),
    rawProductId: field('', z.string().min(1))
        .errorMessage('Informe um produto'),
    quantity: field(0, z.number()),
    measureType: field(MeasureType.Box, z.string()),
    priceType: field(PriceType.UnitPrice, z.string()),
    price: field(0, z.number().gt(0))
        .errorMessage('Informe o preço'),

    validFrom: field(
        new Date().toISOString().split('T')[0],
        z.string(),
    )
        .errorMessage('Informe o inicio da validade dessa cotação'),

    validUntil: field(
        new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * 1, // 1 day
        ).toISOString().split('T')[0], z.string().min(1),
    )
        .errorMessage('Informe o fim da validade dessa cotação'),

    locationId: field(shopper.value?.id_location, z.string().min(1)),
    wholesaleQuantity: field(0, z.number().gt(0)).errorMessage('Informe a quantidade de atacado'),
})

const onCloseModal = () => {
    closeModal()
}

const onSelectedRawProduct = (rawProduct: RawProduct) => {
    selectedRawProduct.value = rawProduct
    quotation.wholesaleQuantity = rawProduct.quantity_wholesale
    quotation.maturationTypeId = null
    quotation.qualityTypeId = null
}

const onSave = async () => {

    validate({
        ignore: [
            quotation.measureType !== MeasureType.Box && 'wholesaleQuantity',
        ],
    })
    console.log('quotation', errors)
    if (!isValid.value) {
        console.log('not_valid')
        return
    }

    closeModal()
    await createProcurementQuotation(quotation)
    success('Cotação salva com sucesso!')
}
</script>

<template>
    <div text-left relative>
        <div text-textcolor-900 font-700 sticky top-0 bg-white z-20 pt-2 pb-4 flex items-center>
            <div>
                Detalhes da cotação
            </div>
            <div ml-auto text-5 @click="onCloseModal">
                <div i-carbon-close />
            </div>
        </div>
        <div>
            <div my-2px text-textcolor-700 text-3>
                Nome do produto
            </div>
            <div>
                <cc-raw-product-select
                    v-model="quotation.rawProductId"
                    :error="errors.rawProductId"
                    label="Produto"
                    placeholder="Selecione um produto"
                    data-test-id="raw-product-list-select"
                    @selected="onSelectedRawProduct"
                    @clear="() => selectedRawProduct = null"
                />
            </div>
            <div>
                <div my-1 text-textcolor-700 text-3>
                    Nome do fornecedor
                </div>
                <cc-provider-select
                    v-model="quotation.providerId"
                    :error="errors.providerId"
                    :withAdd="true"
                />
            </div>
            <labeled-radio
                v-model="quotation.priceType"
                :options="priceOptions"
                label-icon="i-ic-outline-monetization-on"
                label="Tipos de precificação"
            />
            <numbered-input
                v-model="quotation.price"
                :error="errors.price"
                label="Preço"
                label-icon="i-ic-outline-monetization-on"
                positive-only
                currency
                mt-2
            />
            <div mt-2>
                <div my-1 text-textcolor-700 text-3>
                    Validade da cotação
                </div>
                <div grid grid-cols-2 gap-2>
                    <cc-input
                        v-model="quotation.validFrom"
                        label="Válida de"
                        type="date"
                    />
                    <cc-input
                        v-model="quotation.validUntil"
                        label="Válida até"
                        type="date"
                    />
                </div>
            </div>
            <div v-if="selectedRawProduct" mt-2>
                <labeled-radio
                    v-model="quotation.measureType"
                    :options="measureOptions"
                    label="Medida da cotação (opcional)"
                />
                <numbered-input
                    v-if="quotation.measureType === 'box'"
                    v-model="quotation.wholesaleQuantity"
                    :error="errors.wholesaleQuantity"
                    :label="
                        itemMeasureOption.value === MeasureType.Weight
                            ? 'Peso da Embalagem (Caixa ou Saco)'
                            : 'Unidades na embalagem (Caixa ou Saco)'
                    "
                    label-icon="i-mdi-package-variant-closed"
                    :unit="
                        itemMeasureOption.value === MeasureType.Weight
                            ? 'kg'
                            : (`unidade${quotation.wholesaleQuantity !== 1 ? 's' : ''}`)
                    "
                    positive-only
                    mt-2
                />
                <numbered-input
                    v-model="quotation.quantity"
                    :error="errors.quantity"
                    label="Quantidade mínima"
                    label-icon="i-ic:outline-add-to-photos"
                    :allow-decimals="quotation.measureType === MeasureType.Weight"
                    positive-only
                    mt-2
                />
                <cc-quality-type-select
                    v-if="selectedRawProduct.product_policy?.quality_source === 'SOURCING'"
                    v-model="quotation.qualityTypeId"
                    :disabled="selectedRawProduct.product_policy?.quality_source !== 'SOURCING'"
                    :error="errors.qualityTypeId"
                />
                <cc-maturation-type-select
                    v-if="selectedRawProduct.product_family.maturation_sensitive === 'SENSITIVE'"
                    v-model="quotation.maturationTypeId"
                    :disabled="selectedRawProduct.product_family.maturation_sensitive !== 'SENSITIVE'"
                    label="Maturação(Opcional)"
                    :error="errors.maturationTypeId"
                />
            </div>
            <cc-button mt-4 @click="onSave">
                <template #icon>
                    <div v-if="isSavingQuotation" i-eos-icons-loading />
                    <div v-else i-material-symbols-add />
                </template>
                Adicionar
            </cc-button>
        </div>
    </div>
</template>
