<script lang="ts" setup>
import { useMotions } from '@vueuse/motion'

const { isSidebarOpen, closeSidebar } = useSidebar()
const motions = useMotions()

const onOverlayClick = () => {
    if (isSidebarOpen.value) {
        closeSidebar()
    }
}
</script>

<template>
    <div>
        <div
            class=" bg-#0E452FB2 --limit-width" :class="[
                isSidebarOpen ? 'op100 z-99999' : '',
            ]"
            op-0
            fixed top-0 bottom-0 transition
            z--1
            @click="onOverlayClick"
        />
        <div class="bg-#151B17 --margin-width" left-0 fixed top-0 bottom-0 z-9999999 />
        <div class="bg-#151B17 --margin-width" right-0 fixed top-0 bottom-0 z-9999999 />
        <transition
            :css="false"
            @leave="(_, done) => motions.sidebar.leave(done)"
        >
            <div
                v-if="isSidebarOpen"
                v-motion="'sidebar'"
                :initial="{ opacity: 0, translateX: -280 }"
                :enter="{ opacity: 1, translateX: 0, transitionDuration: '100ms' }"
                :leave="{ opacity: 0, translateX: -280 }"
                bg-white
                fixed bottom-0 top-0
                right-unset w-280px
                z-999999
                class="--limit-width"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<style scoped>
.--limit-width {
    --app-margin: max(0px, calc(((100vw - var(--app-max-width)) / 2)));
    left: var(--app-margin);
    right: var(--app-margin);
}

.--margin-width {
    width: max(0px, calc(((100vw - var(--app-max-width)) / 2)));
}
</style>
