import type { Page } from '~~/types/api-common'

export const useQualityTypeService = () => {
    const listQualityTypes = async (options: ListQualityTypeParams = {}): Promise<QualityType[] | null> => {
        const params: Record<string, any> = {
            page: options.page || 1,
            size: options.size || 20,
        }

        const data = await api<Page<QualityType>>(
            '/quality_type/', { params },
        )
        return data.items || null
    }

    return { listQualityTypes }
}

interface ListQualityTypeParams {
    size?: number
    page?: number
}

export interface QualityType {
    id_quality_type: string
    name: string
}
