<script lang="ts" setup>
import { useMaturationTypeService } from '~~/composables/services/maturation-type-service'
import type { MaturationType } from '~~/composables/services/maturation-type-service'
import type { SelectItem } from '~~/types/select'

const props = defineProps<{
    label?: string
    error?: string | false
    placeholder?: string
    modelValue?: any
}>()
const emit = defineEmits(['selected', 'update:modelValue', 'clear'])
const { listMaturationTypes } = useMaturationTypeService()

const defaultMaturationTypes = ref<SelectItem[]>([])

const internalValue = ref(defaultMaturationTypes.value.find(item => item.label === 'De vez')?.value)

const maturationTranslations = {
    COMPLETA: 'Madura',
    INCOMPLETA: 'Verde',
    PADRAO: 'De vez',
}

const mapMaturationTypesToSelectItems = (maturationTypes: MaturationType[]): SelectItem<MaturationType>[] =>
    (Array.isArray(maturationTypes)
        ? maturationTypes.map(maturationType => ({
            label: maturationTranslations[maturationType.name.toUpperCase()],
            value: maturationType.id_maturation_type,
        }))
        : []).sort((itemA, itemB) => itemA.label > itemB.label ? 1 : -1)

watchEffect(async () => {
    if (defaultMaturationTypes.value.length === 0) {
        defaultMaturationTypes.value = mapMaturationTypesToSelectItems(await listMaturationTypes({ size: 1000 }))
    }
})

watch([internalValue], ([newValue]) => {
    emit('update:modelValue', newValue)
})

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== undefined && newVal !== oldVal) {
        internalValue.value = newVal
    }
})

const onMaturationTypeSelected = (item: SelectItem<MaturationType>) => {
    emit('selected', item.metadata)
}
</script>

<template>
    <div>
        <cc-select
            v-model="internalValue"
            :items="defaultMaturationTypes"
            :error="error"
            :label="label ?? 'Maturação (Opcional)'"
            :placeholder="placeholder ?? 'Selecione uma maturação'"
            data-test-id="maturation-type-list-select"
            @clear="$emit('clear')"
            @selected="onMaturationTypeSelected"
        />
    </div>
</template>
