<script lang="ts" setup>
defineProps<{
    label?: string
    modelValue?: any
    disabled?: boolean
    type?: string
    error?: string | false
}>()
const emit = defineEmits(['update:modelValue', 'enter'])

const onInput = (event: Event) => emit('update:modelValue', (event.target as HTMLInputElement).value)
</script>

<template>
    <div
        flex b-1 b-primary-100 rounded-2 relative mt-2
        :class="[{ [`cursor-not-allowed b-textcolor-50`]: disabled }]"
    >
        <input
            :value="modelValue" :type="type || 'text'"
            class="peer"
            rounded-2 w-full
            outline-none px-3 pb-2 pt-5
            text-textcolor bg-transparent
            z-2 relative
            :class="[
                disabled ? `!text-textcolor-300 cursor-not-allowed` : '',
            ]"
            :disabled="disabled"
            @input="onInput"
            @keypress.enter="$emit('enter')"
        >
        <div
            v-if="label" absolute text-black top-14px left-3 text-primary-300
            class="peer-focus:top-6px peer-focus:text-13px" z-0
            :class="[
                disabled ? `!text-textcolor-300 cursor-not-allowed` : '',
                modelValue ? '!top-6px !text-13px' : '',
            ]" font-bold transition-all
        >
            {{ label }}
        </div>
    </div>
</template>
