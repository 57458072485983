<script lang="ts" setup>
</script>

<template>
    <div class="background">
        <div vertical-center justify-center h-100vh class="bouncing">
            <div text-16>
                🍄
            </div>
            <div font-bold text-textcolor-500 text-10 relative top--4>
                Shoppingnon
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

.bouncing {
    animation: bounce .4s infinite ease alternate;
}

.background {
    background-color:hsla(100,19%,97%,1);
    background-image:
        radial-gradient(at 50% 50%, hsla(100,19%,97%,1) 0px, transparent 50%),
        radial-gradient(at 100% 0%, hsla(155,80%,24%,1) 0px, transparent 50%),
        radial-gradient(at 0% 100%, hsla(155,80%,24%,1) 0px, transparent 50%);
    background-size: 300% 300%;
    animation: background 3s infinite ease alternate;
}

@keyframes background {
    0% {
        background-position: 25% 0%;
    }
    100% {
        background-position: 0% 25%;
    }
}
</style>
