<script lang="ts" setup>
import { useRawProductService } from '~~/composables/services/raw-product-service'
import type { RawProduct } from '~~/composables/services/raw-product-service'
import type { SelectItem } from '~~/types/select'

defineProps<{
    label?: string
    error?: string | false
    placeholder?: string
}>()
const emit = defineEmits(['selected', 'update:modelValue', 'clear'])
const internalValue = ref()
const { listRawProducts } = useRawProductService()
const { shopper } = useShopperStore()

const defaultRawProducts = ref<SelectItem[]>([])

const mapProductsToSelectItems = (products: RawProduct[]): SelectItem<RawProduct>[] =>
    (Array.isArray(products)
        ? products.map(product => ({
            label: product.product_name,
            value: product.id_raw_product,
            image: product.image,
            metadata: product,
        }))
        : []).sort((itemA, itemB) => itemA.label > itemB.label ? 1 : -1)

watchEffect(async () => {
    if (defaultRawProducts.value.length === 0) {
        defaultRawProducts.value = mapProductsToSelectItems(await listRawProducts({ size: 1200, id_location: shopper?.id_location, with_policies: true }))
    }
})

watch([internalValue], ([newValue]) => {
    emit('update:modelValue', newValue)
})

const onRawProductQuery = async (query: string, _) => {
    return defaultRawProducts.value.filter(item => item.label.toLowerCase().includes(query.toLowerCase()))
}

const onRawProductSelected = (item: SelectItem<RawProduct>) => {
    emit('selected', item.metadata)
}
</script>

<template>
    <cc-select
        v-model="internalValue"
        :items="defaultRawProducts"
        :error="error"
        :label="label ?? 'Produto'"
        :placeholder="placeholder ?? 'Selecione um produto'"
        :on-query="onRawProductQuery"
        data-test-id="raw-product-list-select"
        @clear="$emit('clear')"
        @selected="onRawProductSelected"
    />
</template>
