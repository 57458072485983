import type { RawSku } from '~~/types/raw-sku'

export const useRawSkuService = () => {
    const getFeasibleRawSkusWithPackaging = async (
        id_raw_product: string,
        id_quality_type: string,
        id_maturation_type: string,
        id_location: string,
    ): Promise<RawSku[] | null> => {
        const params = {
            id_location,
            with_inward_lot: true,
            id_raw_product,
            id_quality_type,
            id_maturation_type,
        }
        const urlParams = Object.entries(params).filter(e => !!e[1]).map(e => `${e[0]}=${e[1]}`)
        const data = await api<any>(
            `/raw_sku/all_feasible/?${urlParams.join('&')}`,
        )
        return data || null
    }

    return { getFeasibleRawSkusWithPackaging }
}
