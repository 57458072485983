<script lang="ts" setup>
import { useQualityTypeService } from '~~/composables/services/quality-type-service'
import type { QualityType } from '~~/composables/services/quality-type-service'
import type { SelectItem } from '~~/types/select'

const props = defineProps<{
    label?: string
    error?: string | false
    placeholder?: string
    modelValue?: any
}>()
const emit = defineEmits(['selected', 'update:modelValue', 'clear'])
const internalValue = ref()
const { listQualityTypes } = useQualityTypeService()

const defaultQualityTypes = ref<SelectItem[]>([])

const mapQualityTypesToSelectItems = (qualityTypes: QualityType[]): SelectItem<QualityType>[] =>
    (Array.isArray(qualityTypes)
        ? qualityTypes.map(qualityType => ({
            label: qualityType.name,
            value: qualityType.id_quality_type,
        }))
        : []).sort((itemA, itemB) => itemA.label > itemB.label ? 1 : -1)

watchEffect(async () => {
    if (defaultQualityTypes.value.length === 0) {
        defaultQualityTypes.value = mapQualityTypesToSelectItems(await listQualityTypes({ size: 1000 }))
    }
})

watch([internalValue], ([newValue]) => {
    emit('update:modelValue', newValue)
})

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== undefined && newVal !== oldVal) {
        internalValue.value = newVal
    }
})

const onQualityTypeSelected = (item: SelectItem<QualityType>) => {
    emit('selected', item.metadata)
}
</script>

<template>
    <cc-select
        v-model="internalValue"
        :items="defaultQualityTypes"
        :error="error"
        :label="label ?? 'Qualidade'"
        :placeholder="placeholder ?? 'Selecione uma qualidade'"
        :disabled="disabled"
        data-test-id="quality-type-list-select"
        @clear="$emit('clear')"
        @selected="onQualityTypeSelected"
    />
</template>
