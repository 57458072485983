<script lang="ts" setup>
import { storeToRefs } from 'pinia'
const { closeSidebar } = useSidebar()
const user = useSupabaseUser()
const config = useRuntimeConfig()
const { shopper, isImpersonatingShopper } = storeToRefs(useShopperStore())
const userName = computed(() => user.value?.user_metadata?.name || user.value.email)

const itemList = [
    {
        title: 'Lista de compras',
        icon: 'i-material-symbols-list',
        to: '/',
        dataTestId: 'list-page-link',
    },
    {
        title: 'Impersonar',
        icon: 'i-material-symbols-person',
        to: '/impersonate',
        dataTestId: 'impersonate-page-link',
    },
    {
        title: 'Cotações',
        icon: 'i-material-symbols-attach-money',
        to: '/quotations',
        dataTestId: 'quotations-page-link',
    },
    {
        title: 'Sair',
        icon: 'i-material-symbols-logout',
        to: '/logout',
        dataTestId: 'quotations-page-link',
    },
]
</script>

<template>
    <app-sidebar-container>
        <div py-4>
            <div v-if="user" flex items-center flex-col>
                <div v-if="userName" w-16 h-16 bg-primary-300 rounded-full text-white font-900 text-8 flex items-center justify-center b-4 b-primary-100>
                    {{ userName[0] }}
                </div>
                <div
                    mt-2 text-textcolor font-800 :class="[
                        userName.length > 20 ? 'text-sm' : '',
                    ]"
                >
                    {{ userName }}
                </div>
                <div text-textcolor-300 text-14px>
                    {{ shopper?.location.name || 'Não é um shopper' }} <span v-if="isImpersonatingShopper">*</span>
                </div>
                <div v-if="isImpersonatingShopper" text-textcolor-300 text-10px>
                    Localização Personificada
                </div>
            </div>
            <div>
                <div w-240px h-2px bg-backgroundlight my-5 mx-auto />
                <nuxt-link
                    v-for="item in itemList" :key="item.title"
                    :to="item.to"
                    flex items-center text-primary
                    hover:bg-backgroundlight
                    px-4 py-2 transition
                    :data-test-id="item.dataTestId"
                    @click="() => closeSidebar()"
                >
                    <div :class="item.icon" mr-3 />
                    <div font-800>
                        {{ item.title }}
                    </div>
                    <div i-material-symbols-chevron-right ml-auto />
                </nuxt-link>
            </div>
            <div mt-12>
                <div font-bold text-textcolor-300 text-center my-1 text-14px>
                    Versão do aplicativo
                </div>
                <div text-12px text-textcolor-100 max-w-120px mx-auto break-words text-center>
                    {{ config.public.appVersion }}
                </div>
            </div>
        </div>
    </app-sidebar-container>
</template>
